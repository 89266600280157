* {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
   }
   .App {
     font-family: sans-serif;
     width: 100%;
     min-height: 100vh;
   }
   
   .App .navbar {
     width: 100%;
     min-height: 10vh;
     display: flex;
     justify-content: space-around;
     align-items: center;
     color: #fff;
     background: #05b025;
     position: sticky;
     top: 0;
   }
   
   .App .navbar .nav-links {
     width: 70%;
     display: flex;
     justify-content: space-around;
     list-style: none;
   }

   .App .navbar .nav-links.active  {
     text-decoration: underline;
     font-weight: 500;
   }
   .App .navbar .nav-links :hover {
     /* background-image: url("images/backgrounds/front_yard_1.png"); */
     /* background-size: "cover"; */
     width:'15px';
     height:'1400px';
     color: rgb(218, 67, 67);
     /* background-image: url('images/backgrounds/flying-pink-butterfly-stickers-wpamgazg0ds9dv6a.webp');  */
 }
   .App .navbar .nav-links>a{
     color: #035924;
     text-decoration: none;
   }
   
/* Dropdown Button */
.dropbutton {
  background-color: #05b025;
  /* color: #035924; */
  padding: 16px;
  /* font-size: 16px; */
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #05b025;
  min-width: 160px;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #035924;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbutton {background-color: #3e8e41;}