@import url('https://fonts.googleapis.com/css2?family=Qwigley');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900');

.whiteBodyFontLeft {
     color: white;
     font-family: "Montserrat Alternates", sans-serif;
     text-align: left;
}

.whiteBodyFontRight {
     color: white;
     font-family: "Montserrat Alternates", sans-serif;
     text-align: right;
}

.whiteBodyFontCenter {
     color: white;
     font-family: "Montserrat Alternates", sans-serif;
     text-align: center;
}

.greenBodyFontLeft {
     color: rgba(44, 105, 4, 0.779);
     font-family: "Montserrat Alternates", sans-serif;
     text-align: left;
}

.greenBodyFontRight {
     color:rgba(44, 105, 4, 0.779);
     font-family: "Montserrat Alternates", sans-serif;
     text-align: right;
}

.greenBodyFontCenter {
     color: rgba(44, 105, 4, 0.779);
     font-family: "Montserrat Alternates", sans-serif;
     text-align: center;
     
}

.blackBodyFontLeft {
     color: black;
     font-family: "Montserrat Alternates", sans-serif;
     text-align: left;
}

.blackBodyFontRight {
     color: black;
     font-family: "Montserrat Alternates", sans-serif;
     text-align: right;
}

.blackBodyFontCenter {
     color: black;
     font-family: "Montserrat Alternates", sans-serif;
     text-align: center;
}

.whiteFancyHeadFontLeft {
     color: white;
     font-family: "Qwigley", bold;
     text-align: left;
     font-size: xx-large;
}

.whiteFancyHeadFontRight {
     color: white;
     font-family: "Qwigley";
     text-align: right;
     font-size: xx-large;
}

.whiteFancyHeadFontCenter {
     color: white;
     font-family: "Qwigley";
     text-align: center;
     font-size: xx-large;
}

.greenFancyHeadFontLeft {
     color: rgba(44, 105, 4, 0.779);
     font-family: "Qwigley";
     text-align: left;
     font-size: xx-large;
}

.greenFancyHeadFontRight {
     color:rgba(44, 105, 4, 0.779);
     font-family: "Qwigley";
     text-align: right;
     font-size: xx-large;
}

.greenFancyHeadFontCenter {
     color: rgba(44, 105, 4, 0.779);
     font-family: "Qwigley";
     text-align: center;
     font-size: xx-large;
     font-style: italic;
}

.blackFancyHeadFontLeft {
     color: black;
     font-family: "Qwigley";
     text-align: left;
     font-size: xx-large;
}

.blackFancyHeadFontRight {
     color: black;
     font-family: "Qwigley";
     text-align: right;
     font-size: xx-large;
}

.blackFancyHeadFontCenter {
     color:black;
     text-align: center;
     font-family: "Qwigley";
     font-size: xx-large;
}

.mainContainerBG {
     background-image: url("../images/backgrounds/front_yard_1.png");
     background-size: "cover";
     width:'100%';
     height:'1400px'
}

.galleryCenter {
     width: '80%';
     height: '70%';
     align-items: 'center';
     justify-content: 'center';
}